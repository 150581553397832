<template>
  <div>
    <b-alert variant="danger" show>개발중인 화면입니다.</b-alert>
    <b-tabs v-model="tabIndex">
      <b-tab title="임시탭">
        <stat></stat>
      </b-tab>
      <b-tab title="빈탭">
        <div v-if="loaded[1]">
          Loaded
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import stat from './Stat.vue'

export default {
  name: 'PartnerGradeMain',
  title: '파트너 등급 관리',
  components: {stat},
  data() {
    return {
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
}
</script>
