<template>
  <div>
    <b-row>
      <b-col md="6">
        <h5>월별 파트너 등급 현황</h5>
        <htb :config="gradeHistoryConfig"></htb>
      </b-col>
      <b-col md="6">
        <h5>파트너 등급별 수수료/마진율 진행 현황</h5>
        <b-btn @click="moveMonth('prev')">Prev</b-btn>
        {{ monthObj.cur }}
        <b-btn @click="moveMonth('next')">Next</b-btn>
        <htb ref="htbGrade" v-model="items.gradeMonth" :config="gradeConfig"></htb>
        <htb ref="sample" v-model="items.gradeMonth" :controls="true"></htb>
      </b-col>
      <b-col md="6">
        <h5>1개월 거래액 순위</h5>
        <htb v-model="items.salesRank" :config="rankConfig" height="500px"></htb>
      </b-col>
    </b-row>

    <hr/>

    <htb ref="sample" :sample="true" height="50vh"></htb>

    <div>
      기능모듈임시모음
    </div>
    <b-btn @click="modal.detail = true">등급별 정책 신규등록</b-btn>

    <b-modal title="가격설정 상세" size="xl" v-model="modal.detail" ok-title="저장" cancel-title="닫기" @hide="modalHide">
      <b-row class="mt-1">
        <b-col cols="2">
          <small>No</small><br/>
          <div class="col-form-label">{{ item.no }}</div>
        </b-col>
        <b-col v-if="item.shop_id">
          <small>Shop</small><br/>
          <b-input :value="``" disabled></b-input>
        </b-col>
        <b-col>
          <small>타이틀</small><br/>
          <b-form-input v-model="item.name"></b-form-input>
        </b-col>
      </b-row>

      <div class="clearfix mt-2">
        <div class="pull-right">
          <b-button class="mr-1" variant="success" size="sm" @click="down()">Xlsx Down</b-button>
        </div>
        <b-form inline>
          <b-button class="ml-1" variant="info" size="sm" @click="showRows()">행 데이터</b-button>
        </b-form>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button v-if="item_org.init" variant="outline-danger" @click="remove()" :disabled="busy.remove">
          삭제
          <b-spinner class="ml-1" small v-if="busy.remove"></b-spinner>
        </b-button>
        <b-button v-if="$R('DEV')" variant="outline-light" @click="$modal.show({title: 'JSON 보기', type: 'json', item: item_org})">
          JSON
        </b-button>
        <b-button variant="primary" @click="ok()" :disabled="busy.save">
          저장
          <b-spinner class="ml-1" small v-if="busy.save"></b-spinner>
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<style>
.h200 {
  height: 200px;
}
</style>
<script>
import htb from '@/views/modules/HotTableBase.vue'
import Handsontable from "handsontable";

export default {
  name: 'PartnerStat',
  components: {htb},
  data() {
    return {
      item: {},
      item_org: {},
      modal: {detail: false},
      busy: {remove: false, save: false},
      monthObj: {},

      items: {
        gradeMonth: [],
        salesRank: []
      },
      gradeHistoryConfig: {
        settings: {
          // data: Handsontable.helper.createSpreadsheetData(100, 50),
          columns: [
            {data: 'shop_id', name: 'Shop ID', className: 'htCenter', readOnly: true},
            {data: 'qty', name: '판매수량', className: 'htCenter', readOnly: true},
            {data: 'sales', name: '거래액', className: 'htCenter', renderer: 'comma', readOnly: true},
          ],
          data: [],
          height: 320,
          colWidths: 47,
          rowHeaders: true,
          // colHeaders: true,
          contextMenu: true,
          // nestedHeaders: [
          //   ['', {label: '수수료 할인', colspan: 2}],
          //   ['등급', '최대 할인율(%)', '최대 할인금액(원)']
          // ],
          // mergeCells: [
          //   { row: 1, col: 1, rowspan: 3, colspan: 3 },
          //   { row: 3, col: 4, rowspan: 2, colspan: 2 },
          //   { row: 5, col: 6, rowspan: 3, colspan: 3 }
          // ],
        },
      },
      gradeConfig: {
        a: 1,
        fields: [
          {name: '등급', key: 'gradeName'},
          {name: '최대 할인율(%)', key: 'maxDcRate', type: 'number', renderer: 'percent:1:1'},
          {name: '최대 할인금액(원)', key: 'maxDcPrice', type: 'number', renderer: 'comma'},
          {name: '할인 지원 대상 최대 GAP(%)', key: 'maxGapRate', type: 'number', renderer: 'percent:1:1'},
          {name: 'Max(원)', key: 'marginMaxRate', type: 'number', renderer: 'percent:1:1'},
          {name: 'Min(원)', key: 'marginMinRate', type: 'number', renderer: 'percent:1:1'},
        ],
        settings: {
          nestedHeaders: [
            ['', {label: '수수료 할인', colspan: 3}, {label: '기준 마진율', colspan: 2}],
            ['등급', '최대 할인율(%)', '최대 할인금액(원)', '할인 지원 대상 최대 GAP(%)', 'Max(원)', 'Min(원)']
          ],
          // mergeCells: null,
        }
      },
      rankConfig: {
        fields: [
          {name: '순위', key: 'rank', type: 'numeric'},
          {name: 'Shop ID', key: 'shop_id', type: 'numeric'},
          {name: '판매수량', key: 'qty', type: 'number', renderer: 'modifiedNumeric', readOnly: false},
          {name: '거래액', key: 'sales', type: 'number'},
          {name: 'HTML', key: 'html1', type: 'string', renderer: 'html'},
          {
            name: '기능', key: 'btn_edit', width: 60, readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
              td.className = cellProperties.className;
              Handsontable.dom.empty(td);
              const onclick = () => this.showRow(this.items.salesRank[cellProperties.row]);

              // A - html 교체로 생성
              td.innerHTML = '<button class="btn btn-light btn-sm">보기</button>';
              td.firstChild.onclick = onclick;

              // B - element 생성 후 추가
              // const btn = document.createElement('BUTTON');
              // btn.className = 'btn btn-light btn-sm';
              // btn.innerText = '보기';
              // btn.onclick = onclick;
              // td.appendChild(btn);

              return td;
            }
          },
        ],
        labelMap: {sales: '거래액', qty: '판매수량'},
        settings: {
          className: 'htMiddle htCenter c-black', // cell 별 class
          // columns: [
          //   {data: 'shop_id', name: 'Shop ID2', className: 'htCenter', readOnly: true},
          //   {data: 'qty', name: '판매수량', className: 'htCenter', readOnly: true},
          //   {data: 'sales', name: '거래액', className: 'htCenter', renderer: 'comma', readOnly: true},
          // ]
        }
      }
    };
  },
  async created() {
    this.monthObj = this.getMonth();
    this.getGradeMonth().then();
    this.getShopRank().then();
  },
  async mounted() {
    console.log(this.$refs.sample)
  },
  methods: {
    showRows() {
      this.$modal.show({title: '가격 설정 데이터', item: this.item.goods, type: 'json'});
    },
    getMonth(month) {
      const m = this.$moment(month);
      return {cur: m.format('YYYY-MM'), prev: m.add(-1, 'month').format('YYYY-MM'), next: m.add(2, 'month').format('YYYY-MM')};
    },
    moveMonth(direction) {
      if (direction === 'prev') this.monthObj = this.getMonth(this.monthObj.prev);
      else if (direction === 'next') this.monthObj = this.getMonth(this.monthObj.next);
      this.getGradeMonth().then();
    },
    /**
     * 특정 월의 등급제 설정을 가져온다.
     */
    async getGradeMonth() {
      const j = await this.$api.postJson('/shop/grade/listMonth', {type: 'all', month: this.monthObj.cur});
      if (j) {
        if (j.list.length === 0) {
          // 한 row 라도 빈 string 포함 모든 컬럼값이 있어야, nestedHeader 가 깨지지 않는다.
          this.items.gradeMonth = [{gradeName: '없음', maxDcRate: 10, maxDcPrice: '', marginMaxRate: 1, maxGapRate: '', marginMinRate: ''}, {maxDcRate: 11}];
          // Vue.set(this.gradeConfig.settings, 'mergeCells', [{ row: 0, col: 0, rowspan: 1, colspan: 2 }]);
          // this.items.gradeMonth = j.list;
        } else {
          this.items.gradeMonth = j.list;
        }
        this.gradeConfig.a++;
      }
    },
    /**
     * 1개월간의 매출 및 순위를 가져온다.
     */
    async getShopRank() {
      this.$api.postJson('/goods/es/elasticCloud', {index: 'bl_order', query: this.makeOrderStatQuery('shop_id', true)}).then(res => {
        const list = res.data.aggregations.group.buckets.map(e => ({shop_id: e.key, sales: e.sales.value, qty: e.qty.value}));
        list.forEach((e, i) => {
          e._changed = {};
          e.html1 = `<i class="fa fa-smile-o"></i>`;
          e.rank = i + 1;
        });
        this.items.salesRank = list.slice(0, 5);
      });
    },
    makeOrderStatQuery(groupBy, excludeCancel = false) {
      const esQuery = {
        query: {
          bool: {
            filter: [
              {range: {order_date: {gte: this.$utils.kstD(this.$moment().add(-1, 'month'))}}}
            ]
          }
        },
        size: 0,
        aggs: {
          group: {
            terms: {field: groupBy, order: {sales: 'desc'}, size: 100},
            aggs: {
              sales: {sum: {field: 'sales_price',}},
              qty: {sum: {field: 'qty'}}
            }
          },
        },
      };
      if (excludeCancel) {
        esQuery.query.bool.must_not = [{terms: {order_status: ['취소확인', '반품완료', '품절']}}];
      }
      return esQuery;
    },
    remove() {
      this.$alertTop('remove');
    },
    async modalHide(event) {
      if (event.trigger === 'ok') {
        event.preventDefault && event.preventDefault();
        this.$alertTop('save');
      } else if (~['cancel', 'headerclose', 'esc', 'backdrop'].indexOf(event.trigger)) {
        // this.pageDiff();
        if (!this.item.saved) {
          if (event.trigger === 'cancel' || event.trigger === 'headerclose') {
            if (!confirm('변경사항을 저장하지 않으시겠습니까?')) {
              event.preventDefault && event.preventDefault();
            }
          } else {
            event.preventDefault && event.preventDefault(); // 이벤트 무시
          }
        } else { // 변경사항이 없으면
          // pass
        }
      }
    },
    async down() {
      this.$alertTop('down');
    },
    showRow(row) {
      this.$modal.show({title: 'JSON 보기', item: row, type: 'json'});
    },
  }
}
</script>
